<template>
  <div v-loading="saveClientAppLoading">
    <el-form
      :model="formData"
      :rules="rules"
      ref="clientAppRegisterForm"
      label-position="top"
    >
      <div>
        <el-form-item
          label="Organization"
          prop="organizationId"
        >
          <el-select v-model="formData.organizationId" style="width:100%;" placeholder="Select">
            <el-option
              v-for="company in companies"
              :key="company.id"
              :label="company.companyName"
              :value="company.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Name"
          prop="name"
        >
          <el-input
            v-model="formData.name">
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button round type="primary" @click="saveClientApp">
        Confirm
      </el-button>
      <el-button outline round @click="handleCloseDialog">
        Cancel
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'clientAppRegister',
  data() {
    const rules = {
      organizationId: [
        {
          required: true,
          message: "This field is required",
          trigger: ["blur", "change"],
        },
      ],
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: ["blur", "change"],
        },
      ]
    }
    return {
      rules,
      saveClientAppLoading: false,
      formData: {
        organizationId: null,
        name: null
      },
      companies: []
    }
  },
  mounted () {
    this.getOnlineCompanies()
  },
  methods: {
    async saveClientApp() {
      this.$refs.clientAppRegisterForm.validate(async (valid) => {
        if (!valid) return;
        this.saveClientAppLoading = true;

        try {
          const res = await this.$request.post({
            url: `${this.$apis.clientApps}/register`,
            data: this.formData
          })

          this.$emit('registered')
          this.handleCloseDialog()
        }
        catch (e) {
          this.$message({
              type: "error",
              message: "Error Saving Client Application",
            });
        }
        finally {
          this.saveClientAppLoading = false
        }
      })

    },
    handleCloseDialog() {
      this.$emit('update:registerDialogVisible', false)
      this.resetForm();

    },
    resetForm() {
      this.formData = {
        organizationId: null,
        name: null,
      };

      this.$refs.clientAppRegisterForm.resetFields();
    },
    async getOnlineCompanies() {
      const res = await this.$request.get({
        url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&offline=false`
      })
      if (res?.code === 1000) {
        this.companies = res.data?.records
      }
    },
  }
}
</script>
